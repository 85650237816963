@import '../Site.Variables.scss';

.side-bar {
    .icon-container:hover {
        background-color: rgba(255, 255, 255, .04);
    }

    .is-active {
        .icon-container:hover {
            background-color: rgba(255, 255, 255, .1);
        }

        .icon-container {
            position: relative;
            background-color: rgba(255, 255, 255, .1);

            .blue-strip {
                position: absolute;
                top: 0;
                left: 3px;
                width: 5px;
                height: 100%;
                background-color: #58B1F5;
            }

            .black-strip {
                position: absolute;
                top: 0;
                left: 0;
                width: 3px;
                height: 100%;
                background-color: $isamsCharcoalGrey;
            }
        }
    }
}



.my-tooltip > .tooltip-inner {
    background-color: #5E5C69;
    color: white;
    border: 1px solid #5E5C69;
    opacity: 1;
    font-size: 12px;
    padding: 7px;
    border-radius: 4px;
    margin-left: 4px;
}

.my-tooltip.show {
    opacity: 1;
} 

.my-tooltip .arrow {
    top: 12px;
    margin-left: 4px;
}

.my-tooltip .arrow::before {
    border-right-color: #5E5C69;
}