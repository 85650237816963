@import "../../Site.Variables.scss";

.admin-view {
    .grey-head-row {
        height: 32px;
        line-height: 32px;
        color: $isamsBlack;
        font-size: 16px;
        font-weight: 500;
        background-color: rgb(245, 245, 245);
        border-bottom: 1px solid #E0E1E1;
    }

    .fs-13 {
        font-size: 13px;
        font-weight: 600;
    }
}