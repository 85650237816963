@import "../Site.Variables.scss";

.wrapper {
	background-color: white;
}

.row {
	background-color: $isamsGrey;
	padding: 5px;
	border-bottom: 1px solid #e0e1e1;
	cursor: pointer;

	span {
		font-weight: 700;
	}
}

.rowNoBorbot {
	background-color: $isamsGrey;
	padding: 5px;
	border-bottom: none;
	cursor: pointer;

	span {
		font-weight: 700;
	}
}
