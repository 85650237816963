.user-header {
    .sign-out-drp-down {
        margin-left: 2rem; 

        button {
            background-color: transparent;
            border: none;
            box-shadow: none;
        }

        button:active {
            background-color: transparent !important;
        }


    }

    .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
        background-color: transparent !important;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        box-shadow: none !important;
    }
}