.landing {
    @keyframes redFlash {
        0% {background-color: rgba(255, 0, 0, .1)}
        16% {background-color: rgba(255, 0, 0, .2)}
        32% {background-color: rgba(255, 0, 0, .3)}
        48% {background-color: rgba(252, 0, 0, .4)}
        62% {background-color: rgba(255, 0, 0, .3)}
        78% {background-color: rgba(255, 0, 0, .2)}
        100% {background-color: rgba(255, 0, 0, .1)}
    }
    
    .red-flash {
        background-color: transparent;
        animation-name: redFlash;
        animation-duration: .8s;
    }
}