@import "../Site.Variables.scss";

.wrapper{
    background-color: white;
}

.row {
    background-color: $isamsGrey;
    padding: 10px;
    border-bottom: 1px solid #E0E1E1;
    cursor: pointer;
}