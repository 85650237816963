.numberBadge {
    background-color: #008000;
    height: 17px;
    width: 17px;
    display: flex;
    align-items: center;
    border-radius: 2px;

    span {
        margin: auto;
        color: white;
        font-size: 10px;
    }
}

.emptyBadge {
    background-color: #2196F3;
    height: 17px;
    width: 17px;
    border-radius: 2px;
}