﻿//AJ: https://getbootstrap.com/docs/4.0/getting-started/theming/
@import "Bootstrap.Overrides.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "Bootstrap.Utilities.scss";

//AJ: This might not cover all cases, such as "disabled".
//AJ: If your case isn't handled, then impliment it!
//AJ: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_navbar.scss
//AJ: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_dropdown.scss
//AJ: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
.navbar-dark {
    .dropdown-menu {
        background-color: $dark !important;
    }

    .dropdown-item {
        @include hover-focus {
            color: $white;
            text-decoration: none;
            @include gradient-bg($gray-600);
        }
    }
}