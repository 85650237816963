.admin-workbook {
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    .name {
        color: #5D5B69;
        font-size: 13px;        
    }

    .download {
        min-width: 120px;
        color: #58B1F5;
        text-decoration: underline;
        font-display: 11px;
        cursor: pointer;
    }
    .download:hover {
        color: #2e8dd6;
    }
}

.no-botbor {
    border-bottom: none;
}