@import "../Site.Variables.scss";

.transition {
	transition: all 2s linear;
}

.badge {
    //padding: 8px 4px 8px 4px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500 !important;
    padding: 4px 7px;

    &.notuploaded {
        background-color: $isamsDarkGrey;
        color: white;
    }
}

.errorcounter {
	display: inline-block;
	min-width: 75px!important; 
	text-align: right;
}

.rotate {
	transition: all 0.5s linear;
}

.rotateDown {
	transform: rotate(180deg);
}

.validated {
    background-color: $isamsGrey;
}

.noIndicator,
.validIndicator,
.errorIndicator {
	height: 17px;
	width: 17px;
	
	&.errorIndicator {
		background-color: red;
	}
	&.validIndicator {
		background-color: green;
	}
	&.noIndicator {
		background-color: $isamsDarkGrey;
	}
}

.indicator
{	
	height: 17px;
	width: 17px;
	text-align: center;
	border-radius: 2px;

	span {
		display: inline-block;
		vertical-align: middle;
		line-height: 10px;
		margin-top: -3px;
        color: white;
		font-size:10px;
		font-weight: 300 !important;
    }
}

.errored {
	background-color: $errored;
}
