﻿//AJ: Utilities that you think would be in bootstrap ... but aren't ...
@import "~bootstrap/scss/_variables.scss";

//AJ: https://stackoverflow.com/questions/37609642/why-does-flexbox-stretch-my-image
.align-self-center {
    align-self: center
}

//AJ: https://github.com/twbs/bootstrap/issues/26221#issuecomment-380180148
.custom-control-no-label {
    padding-left: 1em;
}

//AJ: https://stackoverflow.com/questions/45524214/how-do-i-stop-my-web-content-from-shifting-left-when-the-vertical-scrollbar-appe
.fix-scrollbar-y-move {
    padding-left: calc(100vw - 100%);
}

.lh-n {
    line-height: normal;
}

.lh-u {
    line-height: unset;
}

.c-pointer {
    cursor: pointer;
}

$font-size-xs: ($font-size-base * .75) !default;