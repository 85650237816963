@import "../Site.Variables.scss";

.userHeader {
	height: 64px;
	background-color: white;
	color: $isamsBlack;
	box-shadow: rgba(0,0, 0, .16) 0 3px 6px;
	z-index: 999;

	.row {
		margin-left: 15px;
		margin-right: 15px;
	}
}

.avatar {
	margin-left: 25px;
	vertical-align: middle;
	width: 36px;
	height: 36px;
	border-radius: 50%;
}

.username {
	font-size: 16px;
	margin-left: 25px;
	button {
		background-color: transparent;
		color: #373948;
		border: 0;
		box-shadow: none !important;
	}

	button:hover {
		background-color: transparent;
	}
} 
