
.up-arrow {
	width: 22px;

	svg {
		width: 16px;
		height: 9px;
		position: relative;
		top: -1px;
	}
}
.error-collapse {
	.red-border {
		border:  2px solid rgba(230, 54, 44, 0.15);
		border-top: none;
	}

	.neut-border {
		border: 2px solid rgba(123, 123, 123, .15);
		border-top: none;
	}
}