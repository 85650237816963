@import "../Site.Variables.scss";

.defaultlayout {
    .header {
        height: 28px;
        background-color: #3D3D4A;
        border-bottom: 2px solid $isamsCharcoalGrey;
    }

    .defaultlayout-hiddensearch {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .defaultlayout-headerbackground {
        background-position: right 0 bottom 0;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .layoutwrapper {
        display: flex;
        width: 100%;
        //align-items: stretch;
        #sidebar {
            min-width: 100px;
            max-width: 100px;
            min-height: 89vh;
            background-color: $isamsLightBlack;
        }
    }

    .white-content-container {
        box-shadow: rgba(0,0,0, .16) 0 0 3px;
        border-radius: 1px
    }
}
