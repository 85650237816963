@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import "Bootstrap.scss";
@import "Site.Variables.scss";
@import url(https://fonts.googleapis.com/css?family=Roboto);

html,
body,
#app-root {
	width: 100%;
	height: 100%;
	font-family: Roboto;
	font-size: 13px;
}

.row {
	margin-left: 0;
	margin-right: 0;
}

// #navbar {
// 	.row {
// 		margin-left: 15px;
// 		margin-right: 15px;
// 	}
// }

.background-gray {
	background-color: $isamsGrey;
}

.f-11 {
	font-size: 11px;
}
.f-12 {
	font-size: 12px;
}
.f-13 {
	font-size: 13px;
}
.f-16 {
	font-size: 16px;
}
.cursor-default {
	cursor: default;
}
.cursor-pointer {
	cursor: pointer;
}
