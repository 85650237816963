.admin-row {
    cursor: pointer;
    height: 36px;
    line-height: 36px;

    .badge {
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        width: 80px;
        line-height: 20px;
        border-radius: 2px;
    }
}

.ad-blue {
    background-color: rgba(166, 211, 243, 0.42);
}

.ad-green {
    background-color: rgba(170, 232, 170, 0.45);
}