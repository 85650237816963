.upload {

    .uploader  {
        border: 2px dashed rgb(167, 167, 167);
        width: 100%;
        height: 100;
        background-color: white;
    }

    .uploader-accept {
        border: 2px solid green;
        background-color: rgba(0, 255, 0, .05);
    }

    .uploader-reject {
        border: 2px solid red;
        background-color: rgba(255, 0, 0, .05);
    }
}