﻿$isamsBlack: #373948;

$isamsCharcoalGrey: #1C1D23 ;

$isamsGrey: #f9f8f7;

$isamsLightBlack: #3D3D4A;

$isamsDarkGrey:#4C4C57;

$errored: rgba(230, 54, 44, 0.15)!important;
